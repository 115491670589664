import React, {PropsWithChildren, useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button";
import {mr} from "@pg-design/helpers-css";
import {ChevronDownIcon} from "@pg-design/icons";

import {expandableCollapsedDefaultHeight, expandableDefaultAnimationTime} from "../constants/expandable_defaults";

interface IProps extends PropsWithChildren {
    isAlwaysExpanded?: boolean;
    collapsedHeight?: number;
    hideCollapseButton?: boolean;
    isExpandedByDefault?: boolean;
    customCollapseButton?: (props: {isExpanded: boolean; toggleCollapse: () => void}) => React.ReactNode;
}

const buttonHeight = 50;

export const Expandable = (props: IProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const collapsedHeight = props.collapsedHeight ?? expandableCollapsedDefaultHeight;

    const [states, setStates] = useState(() => ({
        isExpanded: !!props.isExpandedByDefault,
        height: collapsedHeight
    }));

    const getContentHeight = () => {
        const buttonHeightCorrection = props.hideCollapseButton ? 0 : buttonHeight / 2;

        return (contentRef.current?.scrollHeight || 0) + buttonHeightCorrection;
    };

    useEffect(() => {
        if (states.isExpanded) {
            // observe content height change
            const observer = new ResizeObserver(() => {
                setStates((prev) => {
                    return {
                        ...prev,
                        height: getContentHeight()
                    };
                });
            });

            observer.observe(contentRef.current as Element);

            return () => {
                observer.disconnect();
            };
        }
    }, [states.isExpanded]);

    const toggleCollapse = () => {
        setStates((prev) => {
            return {
                ...prev,
                height: prev.isExpanded ? collapsedHeight : getContentHeight(),
                isExpanded: !prev.isExpanded
            };
        });

        setTimeout(() => {
            setStates((prev) => {
                return {
                    ...prev,
                    height: prev.isExpanded ? getContentHeight() : collapsedHeight
                };
            });
        }, expandableDefaultAnimationTime);
    };

    return (
        <div css={wrapperStyle}>
            <div
                css={contentWrapperStyle}
                style={
                    props.isAlwaysExpanded
                        ? {}
                        : {
                              height: `${states.height}px`
                          }
                }
            >
                <div ref={contentRef}>{props.children}</div>
                <div
                    css={!props.isAlwaysExpanded && shadowStyle}
                    style={
                        props.isAlwaysExpanded
                            ? {}
                            : {
                                  height: `${states.isExpanded ? 0 : "60%"}`
                              }
                    }
                />
            </div>

            {props.isAlwaysExpanded || (props.hideCollapseButton && states.isExpanded) ? null : props.customCollapseButton ? (
                props.customCollapseButton({isExpanded: states.isExpanded, toggleCollapse})
            ) : (
                <Button
                    size="small"
                    variant="filled_primary"
                    type="button"
                    css={[
                        buttonStyle,
                        states.isExpanded
                            ? css`
                                  translate: none;
                              `
                            : ""
                    ]}
                    onClick={toggleCollapse}
                >
                    <ChevronDownIcon size="2.4" css={[buttonIconStyle, states.isExpanded ? buttonIconExpandedStyle : buttonIconCollapsedStyle]} />
                    {states.isExpanded ? "Zwiń" : "Rozwiń"}
                </Button>
            )}
        </div>
    );
};

const wrapperStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const contentWrapperStyle = css`
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: height ${expandableDefaultAnimationTime}ms;
`;

const shadowStyle = css`
    height: 60%;
    width: 100%;
    position: absolute;
    top: 40%;
    background: linear-gradient(0deg, #f9f9f9 0%, rgba(249, 249, 249, 0) 100%);
`;

const buttonStyle = css`
    min-width: 20rem;
    transform: translateY(-50%);
`;

const buttonIconStyle = css`
    ${mr(2)};
    transition: transform ${expandableDefaultAnimationTime}ms;
`;

const buttonIconExpandedStyle = css`
    transform: scale(2.4) rotate(180deg);
`;

const buttonIconCollapsedStyle = css`
    transform: scale(2.4) rotate(0);
`;
